import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel, Container } from "react-bootstrap";

import "./style.css";
import { landingBg_lg } from "../../utils/ImageLoaders/Landing";
import { IKImage } from "imagekitio-react";

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderIntroTitle = () => {
    return (
      <Container
        fluid
        className="intro-info d-flex flex-column justify-content-center align-items-center"
      >
        <div>
          <div className="title-container">
            <h1 className="title display-3 fw-bold">
              Transforming Spaces, Elevating Experiences.
            </h1>
          </div>
        </div>
        <Link to="/About" className="glassbtn shadow">
          <span>Learn More</span>
        </Link>
      </Container>
    );
  };

  return (
    <Container
      id="intro-section"
      className="d-flex flex-column justify-content-center align-items-center position-relative"
    >
      <div className={`position-absolute intro-info-box`}>
        {renderIntroTitle()}
      </div>
      <Carousel
        className="landing-carousel"
        indicators={false}
        controls={false}
        pause={false}
        interval={5000}
        fade
      >
        {landingBg_lg
          .sort((a, b) => 0.5 - Math.random())
          .map((img, index) => {
            return (
              <Carousel.Item
                className="landing-carousel-item"
                key={img + index}
              >
                <div className="carousel-item-container">
                  <IKImage
                    className="landing-carousel-image"
                    path={img}
                    alt={`Slide ${index}`}
                  />
                </div>
              </Carousel.Item>
            );
          })}
      </Carousel>
    </Container>
  );
}

export default Landing;
